<template>
    <PageWrapper>
        <GrayBoxAppLayout />
    </PageWrapper>
</template>

<script>
import GrayBoxAppLayout from '@/components/shared/Loader/GrayBoxAppLayout.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

export default {
    name: 'AuthorizedRoute',
    components: {
        GrayBoxAppLayout,
        PageWrapper,
    },
};
</script>
